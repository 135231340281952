@import '../../sass/box-shadow.scss';

.gameControls {
    align-content: center;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;

    & button {
        border: none;
        color: black !important;
        padding: 18px;
        margin: 24px 12px;
        font-size: min(1.25rem, 4vw);
        font-weight: bold;
        background-color: #fba900;
        box-shadow: boxShadow(0, 5, black);
        line-height: 1rem;
    }
}
