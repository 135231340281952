.bingoBoard {
    background-color: #000000;
    width: 100%;
    max-width: 800px;
    padding: 0 min(36px, 3vw);
    padding-bottom: min(36px, 3vw);

    & hr {
        margin: 0;
        margin-bottom: min(36px, 3vw);
        padding: min(2px, 0.25vw) 0;
        background-color: white;
    }
}
.first {
    padding-top: 0;
}

.bingoElements {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    justify-items: stretch;
    grid-auto-flow: column;
    gap: min(36px, 3vw);
}
