
.footer {
    align-items: center;
    background-color: #000000;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    justify-content: center;
    min-height: 100px;
    width: 100%;
}